import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const FindUsWrapperStyles = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 3rem 0;
  h1 {
    text-align: center;
    text-transform: uppercase;
    @media ${media.md} {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
    /* @media ${media.xl} {
      margin-bottom: 3rem;
    } */
  }
  h3 {
    text-align: center;
    margin: 1.5rem 0;
    font-size: 2rem;
    @media ${media.md} {
      text-align: left;
      margin: 2rem 0 0 0;
    }
  }
  .grid {
    .info,
    .left,
    .right {
      margin: 2rem 0;
      section {
        margin: 1rem 0;
        text-align: center;
        > strong {
          color: var(--main);
          font-size: 1.5rem;
        }
        p {
          font-size: 1.2rem;
          margin-top: 0.5rem;
          a {
            color: var(--main);
          }
        }
        ul {
          margin: 1rem auto;
          width: 100%;
          list-style: inside;
          li {
            font-size: 1.2rem;
          }
        }
      }
      .btns {
        margin-top: 1.5rem;
        text-align: center;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        flex-wrap: wrap;
        button {
          width: 100%;
          max-width: 300px;
        }
        .lower-btn {
          margin: 1rem 0;
        }
      }
    }
    .map {
      margin-top: 3rem;
    }
    @media ${media.md} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      .info,
      .left,
      .right {
        section {
          text-align: left;
          br {
            display: none;
          }
        }
        .btns {
          flex-direction: row;
          button {
            span {
              font-size: 1rem;
            }
          }
        }
      }
      .map {
        width: 100%;
        height: 100%;
      }
    }
    @media ${media.lg} {
      grid-gap: 2rem;
    }
  }
  @media ${media.md} {
    .lower {
      padding: 0;
      margin: 1rem 0 0 0;
      .left,
      .right {
        margin: 0;
        padding: 0;
      }
      .left,
      .right section {
        margin: 0;
        padding: 0;
      }
    }
  }
  @media ${media.lg} {
    .top {
      grid-gap: 5rem;
      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .map {
        max-width: 650px;
      }
    }
  }
`
const FindUsWrapper = () => {
  const i = true
  return (
    <FindUsWrapperStyles>
      <h1>Find Us</h1>
      <div className="grid top">
        <div className="info">
          <section>
            <strong>St. Martin's Theatre</strong>
            <p>West Street, London WC2H 9NZ</p>
          </section>
          <section>
            <strong>Public transport</strong>
            <p className="sub">
              <strong>Tube: </strong>
              Leicester Square
            </p>
            <p className="sub">
              <strong>National Rail: </strong>
              Charing Cross
            </p>
            <p className="sub">
              <strong>Bus: </strong>
              Charing Cross Rd, serviced by the following buses: 14, 19, 22, 24,
              29, 38, 40, 176
            </p>
          </section>
          <div className="btns">
            <a
              href="https://tfl.gov.uk/plan-a-journey/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button" className="btn btn-main">
                <span>TFL JOURNEY PLANNER</span>
              </button>
            </a>
            <a
              href="https://www.nationalrail.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button" className="btn btn-main">
                <span>NATIONAL RAIL ENQUIRIES</span>
              </button>
            </a>
            <a
              href="https://citymapper.com/london?lang=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button" className="btn btn-main">
                <span>CityMapper</span>
              </button>
            </a>
          </div>
        </div>
        <div className="map">
          <a
            href="https://www.google.com/maps/place/St.+Martin's+Theatre/@51.5128743,-0.1297649,17z/data=!3m1!4b1!4m5!3m4!1s0x487604cd5e2ad9d9:0xc4c21bd15e2c5595!8m2!3d51.512841!4d-0.1276193"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/map.png"
              alt="St Martins Theatre | The Mousetrap"
            />
          </a>
        </div>
      </div>
      <h3>IN THE AREA</h3>
      <div className="grid lower">
        <div className="left">
          <section>
            <strong>Car Park</strong>
            <p>Driving to London to see The Mousetrap? Take advantage of 50% off at select London Q-Park parking facilities when you visit a participating theatre*.&nbsp;</p>
            <p>*Any over runs will be charged at our standard hourly rate. You must have a valid theatre ticket for the day of your visit which may be checked upon arrival and/or exit.</p>
            <p>Please select your desired car park below &amp; book your discounted 4 hour parking session in advance.</p>
            <div className="btns">
              <a
                href="https://www.q-park.co.uk/en-gb/cities/london/society-of-london-theatre/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-main lower-btn" type="button">
                  <span>BOOK CAR PARK NOW</span>
                </button>
              </a>
            </div>
            <p>Terms and conditions apply.</p>
          </section>
        </div>
        <div className="right">
          <section>
            <strong>Food &amp; Drink</strong>
            <p>
              There are many restaurants, pubs and bars within walking distance
              of the theatre. Seven Dials, Covent Garden and Leicester Square
              are all only a short walk away. Some of our favourites are:
            </p>
            <ul>
              <li>The Ivy (56 feet away)</li>
              <li>
                Dishoom Covent Garden <br /> (220 feet away)
              </li>
              <li>
                Hawksmoor Seven Dials <br /> (0.1 miles away)
              </li>
              <li>
                Thai Square Covent Garden <br /> (0.2 miles away)
              </li>
            </ul>
          </section>
        </div>
      </div>
    </FindUsWrapperStyles>
  )
}

export default FindUsWrapper
